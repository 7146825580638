import {
	addFallbackOwnHome,
	getHeapSessionIdCookieData,
	getFormsServiceApiDomain,
	initDuplicateSubmissionExperience,
	processFormsServiceApiSubmitSuccess,
	swapRecaptchaTokenWithFlag,
	switchFormLoadingState,
	switchFormSubmitState,
	useInitialQueryParamsFromSessionStorage,
	updateFormDataFormatForFormsService
} from '../../helpers';
/**
 * Submit form data to HS Forms Service Lead Capture API endpoint
 * @param {Object} formData - form data
 * @param {Function} successCallback - success callback
 * @param {Function} errorCallback - error callback
 */
export function submitFormDataToFormsServiceApi(formData, successCallback, errorCallback) {
	const apiDomain = getFormsServiceApiDomain(),
		apiUrl = apiDomain + 'forms/inquiries';

	// Add fallback OwnHome value if needed
	formData = addFallbackOwnHome(formData);

	// Remove recaptcha token string and add flag
	formData = swapRecaptchaTokenWithFlag(formData);

	// If user encounters JS error and session refreshes, send initial query params & clean up form data
	formData = useInitialQueryParamsFromSessionStorage(formData);

	// Format to new API formData structure
	const updatedFormData = updateFormDataFormatForFormsService(formData);

	window.modForm.opts.formData = updatedFormData;

	if (window.modForm.isSubmitting === false) {
		window.modForm.isSubmitting = true;
		let response = {};

		const request = new XMLHttpRequest();
		request.open('POST', apiUrl);
		request.setRequestHeader('Content-Type', 'application/json');
		request.send(JSON.stringify(updatedFormData));
		request.addEventListener('load', function() {
			const xhr = this;
			if (this.response) {
				response = { ...response, ...JSON.parse(this.response) };
				if (this.readyState === 4 && this.status === 200) {
					if ('function' === typeof successCallback) {
						successCallback(response);
					}

					if (response.CommissionDetails) {
						if (response.CommissionDetails.firePixel === true) {
							window.modForm.opts.isFirePixelTrue = true;
						}
					}

					// Needed for Frontdoor's Exclusive Match Experience
					if (response) {
						if (formData.PC) {
							response.zip = formData.PC;
						}
						if (formData.SP) {
							response.state = formData.SP;
						}

						if (formData.CT) {
							response.city = formData.CT;
						}
					}

					// E-Commerce Matching Logic (Home Warranty = Frontdoor | Home Security = ADT | Medical Alerts = ADT Health)
					if (window.modForm.opts.eCommerceServices.includes(formData.service)) {
						if (response.vendors.length === 1 && response.vendors[0].vendorKey === window.modForm.opts.sharedMatchVendorKeys.frontdoor) {
							window.modForm.opts.isFrontdoorExclusiveMatch = true;
							window.modForm.opts.frontdoorLeadId = response.vendors[0].clientDetails && response.vendors[0].clientDetails.leadid ? response.vendors[0].clientDetails.leadid : '';
						} else if (response.vendors.length >= 1) {
							const sharedMatchKeys = Object.values(window.modForm.opts.sharedMatchVendorKeys);
							const isSharedMatch = response.vendors.filter(match => sharedMatchKeys.includes(match.vendorKey));
							if (isSharedMatch.length > 0) {
								const matchingKeys = isSharedMatch.map(match => match.vendorKey).join(',');
								window.modForm.opts.sharedParam = `shared=${matchingKeys}`;
								window.modForm.opts.isSharedMatch = isSharedMatch;
							}
						}
					}

					// Change form submit state
					if (window.modForm.opts.showLoadingOverlay) {
						switchFormSubmitState('submitted');
						setTimeout(function() {
							processFormsServiceApiSubmitSuccess(response, xhr);
						}, 1000);
					} else {
						processFormsServiceApiSubmitSuccess(response, xhr);
					}
				} else {
					if (typeof window.modForm.opts.onGetMatchesBlocked === 'function' && this.status === 0) {
						// Status code 0 most common cause: IP Blocked
						window.modForm.opts.onGetMatchesBlocked();
					}
					// If there was an error, we first look for any error callback first
					if (typeof errorCallback === 'function') {
						errorCallback(xhr, this.status, response);
					}
				}
			}
		});

		request.addEventListener('loadend', function() {
			window.modForm.isSubmitting = false;
			switchFormLoadingState(false);
		});

		request.addEventListener('error', function() {
			console.error('Something went wrong!');

			const $loader = document.querySelector('.loader');
			if ($loader) {
				$loader.style.opacity = 0;
				$loader.style.zIndex = -1;
			}

			// Show duplicate submission fallback experience if API encounters network issues
			const heapSessionIdCookieData = getHeapSessionIdCookieData();
			initDuplicateSubmissionExperience(heapSessionIdCookieData);
			window.modUtils.heap('track', ['API Error | Showing Fallback Experience', { function: 'submitFormDataToFormsServiceApi'}]);
		});
	}
}
