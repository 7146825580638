import { serviceCodesAndAttributions } from './serviceCodesAndAttributions';

/**
 * This function maps the serviceSpecificAttributes based on current trade
 * @param {String} serviceCode - serviceCode
 */
export function mapServiceSpecificAttributes(serviceCode) {
	function getAttributeValue(elementName) {
		if (window.modForm.opts.form && elementName) {
			window.modForm.opts.form.querySelectorAll(`input[name="${elementName}"]`).forEach((element) => {
				if (element.type !== 'radio') {
					window.modForm.opts.serviceSpecificAttributes[elementName] = element.value;
				} else if (element.checked) {
					window.modForm.opts.serviceSpecificAttributes[elementName] = (elementName === 'OwnHome' && element.value === 'Authorized') ? 'Yes' : element.value;
				}
			});
		}
	}

	for (const service of serviceCodesAndAttributions) {
		if (service.serviceCodes.includes(serviceCode)) {
			service.requiredAttributes.forEach(attribute => {
				getAttributeValue(attribute);
			});
		}
	}
}
