/**
 * Fallback in case DuplicateSubmission redirect fails
 * @param {string} resultsPage - The results page which will be used as the button link
 */
export function showFallbackForDuplicateSubmission(resultsPage) {
	const activeStepInner = document.querySelector('.swiper-slide-active .step-inner'),
		progressBar = document.querySelector('.progress-bar'),
		formButtons = document.querySelector('.form-btns'),
		formTcpa = document.querySelector('.form-tcpa'),
		backButtons = document.querySelectorAll('.back-btn, .btn-back, [data-btn="back"]'),
		defaultResultsPage = window.modForm.opts.landingPage.includes('bestcompany') ? 'https://bestcompany.com/' : 'https://modernize.com/',
		resultsPageUrl = !resultsPage ? defaultResultsPage : resultsPage;
	// Giving the page a chance to redirect before showing fallback
	setTimeout(function() {
		if (backButtons) {
			backButtons.forEach(function(backButton) {
				backButton.style.display = 'none';
			});
		}
		if (progressBar) {
			progressBar.style.display = 'none';
		}
		if (formButtons) {
			formButtons.style.display = 'none';
		}
		if (formTcpa) {
			formTcpa.style.display = 'none';
		}

		activeStepInner.innerHTML = '<div style="padding:0 10px;text-align:center;"><h3 style="color:#545253;font-size:22px;font-weight:bold;margin:30px auto 0;">Oops!</h3><p style="color:#545253;display:block;font-size:15px;font-weight:300;line-height:1.5;margin:20px auto 30px;">It looks like you just submitted your <br>information a moment ago.</p><a class="btn ' + (window.modForm.opts.addAccessibilityUpdates ? 'btn--primary' : 'btn-primary') + '" id="double-submission-fallback-button" href="' + resultsPageUrl + '" style="max-width:280px;"><span class="btn__text" style="visibility:visible;">See My Matches</span></a></div>';
		window.modUtils.heap('track', ['Showing Fallback - Duplicate Submission']);
	}, 500);
}
