import { getCurrentStep, markFieldAsInvalid, trackInvalidFieldsInHeap } from '../helpers';
import { isFieldValid } from '../validators';
/**
 * Validate Form Fields
 * @param {Object} $form - jquery element containing inputs to validate
 * @returns {Boolean} valid
 */
export function isFormValid($form) {
	let isValid = true;

	if ($form) {
		const invalidFields = [];

		$form.querySelectorAll('[data-required]').forEach(function(element) {
			const $field = element;

			if ('radio' === $field.getAttribute('type')) {
				isValid = $form.querySelectorAll('input[name="' + $field.getAttribute('name') + '"]:checked').length;
			} else if ('checkbox' === $field.getAttribute('type')) {
				isValid = $form.querySelectorAll('input[name="' + $field.getAttribute('name') + '"]:checked').length;
			} else {
				isValid = isFieldValid($field, $field.getAttribute('data-required'));
			}

			if (!isValid) {
				const invalidFieldName = $field.name.charAt(0).toUpperCase() + $field.name.slice(1);

				if (invalidFields.indexOf(invalidFieldName) === -1) {
					invalidFields.push(invalidFieldName);
				}
				markFieldAsInvalid($field);

				isValid = false;
			}
		});

		// Check if there's already an invalid field on current step
		if (window.modForm.opts.steps) {
			const currentStep = getCurrentStep();
			if (currentStep.querySelectorAll('.' + window.modForm.opts.fieldErrorCls).length > 0) {
				isValid = false;
			}
		}

		// Check for invalid inputs
		if (invalidFields.length) {
			invalidFields.forEach(function(fieldName) {
				trackInvalidFieldsInHeap(fieldName);
			});
		}
	}

	return isValid;
}
