import { switchFormLoadingState, getProjExUrl, createHeapSessionIdCookie } from '../../helpers';
import { isQsSiteEligibleForHrc } from '../../validators';
/**
 * Process form after succesful submit
 * @param {Object} response - response data
 * @param {Object} xhr - xhr
 * @returns {Boolean} Submission status
 */
export function processFormsServiceApiSubmitSuccess(response, xhr) {
	let cookieData,
		frontdoorResultsPage = '';
	const responseData = response,
		dataCaptureKey = responseData.dataCaptureKey,
		zip = responseData.zip,
		frontdoorBasesUrl = 'https://shopping.ahs.com/plans/get-my-quote/select?leadData=',
		frontdoorUtmSource = '&utm_medium=affiliate&utm_source=modernize&utm_campaign=modernize_ecomm';

	if (window.modForm.opts.isFrontdoorExclusiveMatch) {
		if (responseData.city && responseData.state) {
			window.modForm.opts.userCity = responseData.city;
			window.modForm.opts.userState = responseData.state;
		} else if (window.modForm.opts.userLocation) {
			const userLocation = window.modForm.opts.userLocation.split(',');
			window.modForm.opts.userCity = userLocation[0].trim();
			window.modForm.opts.userState = userLocation[1].trim();
		}

		const piiData = `;;${window.modForm.opts.userCity};${window.modForm.opts.userState};${zip};${window.modForm.opts.formData.S1};;;;;;;${window.modForm.opts.frontdoorLeadId};`;
		const piiEncoded = btoa(piiData);

		frontdoorResultsPage = `${frontdoorBasesUrl}${piiEncoded}${frontdoorUtmSource}`;

		window.modUtils.heap('track', ['E-Commerce Flow', {
			companyName: response.vendors[0].vendorDisplayName,
			destinationLink: frontdoorResultsPage,
			matchCriteria: 'Exclusive (Redirect)'
		}]);
	}

	// If submission was succesful
	if (dataCaptureKey !== null && xhr.status === 200) {
		const clickKeyUUID = window.modForm.opts.formData.CLK;

		// Setting GA User ID and several custom dimensions after a successful form submit request
		if (typeof dataLayer !== 'undefined') {
			window.dataLayer.push({
				event: 'formSubmit',
				userId: clickKeyUUID,
				lastProjectId: dataCaptureKey,
				projectId: dataCaptureKey,
				projectClass: window.modForm.opts.formData.service
			});
		}

		// Sending GA Event to keep track of all non-TYP conversions, including this one
		window.modUtils.gaSend({
			hitType: 'event',
			eventCategory: 'HRC General',
			eventAction: 'User converted on non-TYP experience',
			eventLabel: window.location.pathname
		}, window.modForm.opts.gaTracker);

		// Redshift Transforms that normally happen on the TYP
		if (window.heap) {
			window.heap.addEventProperties({
				'project_id': dataCaptureKey,
				'data_capture_key': dataCaptureKey,
				'form_path': window.modForm.opts.landingPage
			});
		}

		// Alternate heap conversion event for Homeowner Lead Form Conversion combo event
		window.modForm.homeownerLeadConversionProps.zip = zip;
		window.modUtils.heap('track', ['Homeowner Lead Conversion', window.modForm.homeownerLeadConversionProps]);

		// VWO custom JS goal code
		if (window.modForm.opts.GOAL_ID) {
			window._vis_opt_queue = window._vis_opt_queue || []; // eslint-disable-line camelcase
			window._vis_opt_queue.push(function() {
				_vis_opt_goal_conversion(window.modForm.opts.GOAL_ID); // eslint-disable-line camelcase, no-undef
			});
		}

		// Fire all conversion pixels
		if (typeof window.dataLayer !== 'undefined') {
			window.dataLayer.push({
				event: 'Homeowner Lead Conversion',
				isQSPage: 'true'
			});
		}

		// Track checkout in Pinterest
		if ('undefined' !== typeof pintrk) {
			pintrk('track', 'checkout', { // eslint-disable-line no-undef
				'order_id': 'undefined' !== typeof dataCaptureKey ? dataCaptureKey : null
			});
		}

		// Save response and quadlink params to cookie and redirect to the corresponding thankyou page
		cookieData = Object.assign({}, window.modForm.opts.quadLinkParams, responseData);

		// Set personal information
		cookieData.PROJECT = {};

		if (!isQsSiteEligibleForHrc()) {
			cookieData.PROJECT.FIRSTNAME = window.modForm.opts.formData.FN;
		}

		// Append trade/vertical in cookie to get the reference in result page
		let contractorTrade = window.modForm.opts.contractorTrade ? window.modForm.opts.contractorTrade : window.modForm.opts.vertical;
		contractorTrade = contractorTrade.replace(/_/g, ' ').toLowerCase();
		cookieData.PROJECT.contractorTrade = contractorTrade;

		window.modUtils.setCookie('qsLeadData', JSON.stringify(cookieData));

		if ('function' === typeof window.modForm.opts.formSubmitSuccessCallback) {
			if (window.modForm.opts.formSubmitSuccessCallback(responseData) === false) {
				return false;
			}
		}
		// Handling errors, conditional is when no DCK is found
	} else {
		switchFormLoadingState(false);

		if ('function' === typeof window.modForm.opts.formSubmitErrorCallback) {
			if (window.modForm.opts.formSubmitErrorCallback(responseData) === false) {
				return false;
			}
		}
	}

	// Set results page to HRC if the site is eligible, there's a DCK
	// and the current page is not a quote emerging trade page (these should not redirect to the HRC)
	if (isQsSiteEligibleForHrc() && responseData.publicLeadIdentifer) {
		window.projectId = responseData.publicLeadIdentifer;
		window.modForm.opts.resultsPage = getProjExUrl();
		window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + 'DCK=' + dataCaptureKey;

		if (window.modForm.opts.shouldSuppressConversionPixel) {
			window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + window.modForm.opts.suppressConversionPixelParam;
		}

		if (window.modForm.opts.isFirePixelTrue) {
			window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage?.indexOf('?') < 0 ? '?' : '&') + window.modForm.opts.firePixelParam;
		}

		if (window.modForm.opts.isSharedMatch) {
			window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + window.modForm.opts.sharedParam;
		}

		if (Object.keys(window.modForm.opts.resultsPageAdditionalParams).length) {
			Object.entries(window.modForm.opts.resultsPageAdditionalParams).forEach(function([key, value]) {
				window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + key + '=' + value;
			});
		}

		if (window.modForm.opts.prefVendorParam && (window.modForm.opts.prefVendorKeys && window.modForm.opts.prefVendorKeys.length)) {
			window.modForm.opts.prefVendorParam += window.modForm.opts.prefVendorKeys.toString();
			window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + window.modForm.opts.prefVendorParam;
		}

		if (window.modForm.opts && window.modForm.opts.getVars && 'PartnerSourceID' in window.modForm.opts.getVars) {
			window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + `PartnerSourceID=${window.modForm.opts.getVars.PartnerSourceID}`;
		}

		// Append TY to HRC URL for QS tracking purpose
		window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + (responseData.vendors.length > 0 ? 'ThankYou' : 'Sorry');
	} else {
		// Moving this logic to centralized file where we change the /ThankYou path for /Sorry in case there were no matches
		window.modForm.opts.resultsPage = responseData.vendors.length === 0 ? window.modForm.opts.errorRedirectPath : window.modForm.opts.resultsPage;
	}

	// Append TY to HRC URL for QS tracking purpose
	if (
		typeof window.modForm.opts.appendTyToHrcUrl !== 'undefined'
		&& window.modForm.opts.appendTyToHrcUrl === true
		&& window.modForm.opts.resultsPage.indexOf('ThankYou') < 0
		&& window.modForm.opts.resultsPage.indexOf('Sorry') < 0
	) {
		window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + (responseData.vendors.length > 0 ? 'ThankYou' : 'Sorry');
	}

	if (window.modForm.opts.isFrontdoorExclusiveMatch) {
		window.modForm.opts.resultsPage = frontdoorResultsPage;
	} else if (window.modForm.opts.isAngiExclusiveMatch && window.modForm.opts.angiResultsPage) {
		window.modForm.opts.resultsPage = window.modForm.opts.angiResultsPage;
	} else if (window.modForm.opts.isNetworxExclusiveMatch && window.modForm.opts.networxResultsPage) {
		window.modForm.opts.resultsPage = window.modForm.opts.networxResultsPage;
	}

	// Adding new HRC URL to Loader link
	if (window.modForm.opts.showLoadingOverlay) {
		Loader.updateLink(window.modForm.opts.resultsPage); // eslint-disable-line no-undef
	}

	// Renamed thankyou page to results page to be more explicit that TYP is dying
	if (window.modForm.opts.resultsPage && !window.modForm.opts.isOrganicPreferredMatch && window.modForm.opts.autoRedirectToTyOrSorryPage) {
		if (window.modForm.opts.showRedirectOverlay) {
			if (window.modForm.opts.isFrontdoorExclusiveMatch) {
				Loader.showRedirectAnimation(...window.modForm.opts.eCommerceExclusiveLoaders.frontdoor); // eslint-disable-line no-undef
				window.modUtils.heap('track', ['Loader Visible - Transferring to American Home Shield']);
			} else if (window.modForm.opts.isAngiExclusiveMatch) {
				Loader.showRedirectAnimation(...window.modForm.opts.eCommerceExclusiveLoaders.angi); // eslint-disable-line no-undef
				window.modUtils.heap('track', ['Loader Visible - Transferring to Angi']);
			} else if (window.modForm.opts.isNetworxExclusiveMatch) {
				Loader.showRedirectAnimation(...window.modForm.opts.eCommerceExclusiveLoaders.networx); // eslint-disable-line no-undef
				window.modUtils.heap('track', ['Loader Visible - Transferring to Networx']);
			} else {
				Loader.showRedirectAnimation(); // eslint-disable-line no-undef
				window.modUtils.heap('track', ['Loader Visible - Transferring to Modernize']);
			}
			setTimeout(function() {
				setTimeout(function() {
					window.location.href = window.modForm.opts.resultsPage;
				}, 500);
				document.body.classList.add('submitted');
			}, 2500);
		} else if (window.modForm.opts.showLoadingOverlay) {
			document.body.classList.add('submitted');
			setTimeout(function() {
				window.location.href = window.modForm.opts.resultsPage;
			}, 1000);
		} else {
			window.location.href = window.modForm.opts.resultsPage;
		}
	}

	if (window.modForm.opts.isFrontdoorExclusiveMatch) {
		createHeapSessionIdCookie(encodeURIComponent(window.modForm.opts.resultsPage));
	} else {
		createHeapSessionIdCookie(window.modForm.opts.resultsPage);
	}
}
