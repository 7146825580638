export function initDuplicateSubmissionExperience(heapSessionIdCookieData, activeStep = '.swiper-slide-active .step-inner') {
	const activeStepInner = window.modForm.opts.form.querySelector(activeStep),
		progressBar = document.querySelector('.progress-bar'),
		formButtons = document.querySelectorAll('.form-btns, .form-bts, .controls, .findpros-form__btns'),
		formTcpa = document.querySelector('.form-tcpa'),
		backButtons = document.querySelectorAll('.back-btn, .btn-back, [data-btn="back"]'),
		defaultResultsPage = window.modForm.opts.landingPage.includes('bestcompany') ? 'https://bestcompany.com/' : 'https://modernize.com/';
	let resultsPageUrl = !heapSessionIdCookieData.resultsPage ? defaultResultsPage : heapSessionIdCookieData.resultsPage;

	if (resultsPageUrl.indexOf(window.modForm.opts.suppressConversionPixelParam) <= -1) {
		// suppress conversion pixels in HRC link & fire them from the page instead
		resultsPageUrl += (resultsPageUrl.indexOf('?') < 0 ? '?' : '&') + window.modForm.opts.suppressConversionPixelParam;
	}

	window.modForm.opts.form.classList.add(window.modForm.opts.formLoadingCls);

	const duplicateSubmitFireCnvrsnPxlCookie = window.modUtils.getCookie('duplicateSubmitFireCnvrsnPxl');
	if (!duplicateSubmitFireCnvrsnPxlCookie) {
		if (resultsPageUrl.indexOf('ThankYou') > -1) {
			// fire conversion pixels in case user does not redirect to HRC
			if (typeof window.dataLayer !== 'undefined') {
				window.dataLayer.push({
					event: 'Lead Conversion Failed Redirects'
				});
			}
		}
	}
	window.modUtils.setCookie('duplicateSubmitFireCnvrsnPxl', true);
	window.modForm.preventDuplicateSubmission = true;

	// Giving the page a chance to redirect before showing fallback
	setTimeout(function() {
		if (backButtons) {
			backButtons.forEach(function(backButton) {
				backButton.style.display = 'none';
			});
		}
		if (progressBar) {
			progressBar.style.display = 'none';
		}
		if (formButtons) {
			formButtons.forEach(function(formButton) {
				formButton.style.display = 'none';
			});
		}
		if (formTcpa) {
			formTcpa.style.display = 'none';
		}

		window.modForm.opts.form.querySelector('.swiper-wrapper').style.alignItems = 'center';

		window.modForm.opts.form.classList.remove(window.modForm.opts.formLoadingCls);

		const target = window.modForm.opts.isLeadWidget ? '_blank' : '_self';

		activeStepInner.innerHTML = '<div class="duplicate-submission" style="background-color: #fff; display: flex; flex-direction: column; justify-content: center; align-items: center; min-height: inherit; padding: 40px 10px;text-align:center;"><h3 style="color:#545253;font-size:22px;font-weight:bold;margin:30px auto 0;">Welcome Back!</h3><p style="color:#545253;display:block;font-size:15px;font-weight:400;line-height:1.5;margin:20px auto 30px;">It looks like you just submitted your <br>information a moment ago.</p><a class="btn ' + (window.modForm.opts.addAccessibilityUpdates ? 'btn--primary' : 'btn-primary') + '" id="double-submission-fallback-button" href="' + resultsPageUrl + '" target="' + target + '" style="max-width:280px;line-height: normal;width:100%;display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 14px 0px 15px;margin:0;min-width:unset"><span class="btn__text" style="visibility:visible;font-size: 18px">See Your Matches</span></a></div>';

		window.modUtils.heap('track', ['Showing Duplicate Submission Experience - on Step One', {
			resultsPage: resultsPageUrl
		}]);
	}, 300);
}
