import { backButtonClickCallback } from '../../../helpers';
/**
 * Init back buttons
 * @param {boolean} [addEvent=true] - Determines whether to add an event listener to the back buttons
 */
export function initBackButtons(addEvent = true) {
	const backButtons = document.querySelectorAll('.back-btn, .btn-back, [data-btn="back"]');

	if (backButtons.length) {
		backButtons.forEach((backButton) => {
			if (addEvent) {
				backButton.addEventListener('click', backButtonClickCallback);
			} else {
				backButton.removeEventListener('click', backButtonClickCallback);
			}
		});
	}
}
