import {
	getPhoneNumberType,
	mapDynamicServiceCode
} from '../../helpers';
/**
 * This function processes form attribution data, setting important values like tracking params and response format type
 * @param {Object} formData - form data
 * @returns {Object} formatted form data
 */
export function preprocessQSFormData(formData) {
	let formattedData = {};
	const form = window.modForm.opts.form;
	const qsFieldsTranslation = {
			FN: 'firstName',
			LN: 'lastName',
			HP: 'homePhone',
			EM: 'email',
			S1: 'address',
			CT: 'city',
			SP: 'state',
			PC: 'zip',
			OwnHome: 'homeowner',
			leadid_token: 'leadToken', // eslint-disable-line camelcase
			TrustedFormToken: 'xxTrustedFormToken'
		},
		modFieldsToRemove = [
			'session',
			'sessionToken',
			'usid',
			'landingPage',
			'tcpa',
			'tcpaText',
			'xxTrustedFormToken',
			'xxTrustedFormCertUrl',
			'inertZip',
			'homeowner',
			'zip1'
		];

	const quadLinkParams = window.modForm.opts.quadLinkParams;

	// If we have parameters from the quadlink request we add these to the form data
	if (Object.keys(quadLinkParams).length !== 0) {
		for (const param in quadLinkParams) {
			if (quadLinkParams.hasOwnProperty(param)) { // eslint-disable-line no-prototype-builtins
				formattedData[param] = quadLinkParams[param];
			}
		}

		formattedData.HEAPID = quadLinkParams.CLK;
		formattedData.HeapUserID = quadLinkParams.CLK;
	} else if (window.modUtils.getUrlParamsToObject().hasOwnProperty('tagID')) { // eslint-disable-line no-prototype-builtins
		const { tagID } = window.modUtils.getUrlParamsToObject();
		formattedData.tagID = tagID;
	} else {
		formattedData.tagID = window.modUtils.getEnv() === 'production' ? window.modForm.opts.tagIDLive : window.modForm.opts.tagIDTest;
	}

	// Checking CROTag values and Multiple Windows to change NumberOfWindows
	if (typeof formData.CROTag !== 'undefined') {
		const croTagData = JSON.parse(formData.CROTag);

		if (croTagData.MultipleWindows && croTagData.MultipleWindows === 'Yes' && formData.NumberOfWindows === '1') {
			formData.NumberOfWindows = '3-5';
		}
	}

	// Setting VWO campaign data if there is an active test
	if (window._vwo_campaignData && Object.keys(window._vwo_campaignData).length !== 0) {
		formattedData.VWOID = Object.keys(window._vwo_campaignData)[0];
		formattedData.VWOVariationName = Object.values(window._vwo_campaignData)[0].n;
	}

	// Setting Heap Session ID
	if (window.modUtils.heapCookie) {
		formattedData.HeapSessionID = window.modUtils.heapCookieData.sessionId;
	}

	// Translating our form fields name to Quinstreet nomenclature
	Object.keys(qsFieldsTranslation).forEach(function(key) {
		if (formData[qsFieldsTranslation[key]]) {
			formattedData[key] = formData[qsFieldsTranslation[key]];
			delete formData[qsFieldsTranslation[key]];
		}
	});

	// Dynamically setting the correct Service / Vertical
	formData.service = mapDynamicServiceCode(formData);

	// Remove unnecessary fields (for now we're doing it this way, will look for a better way in the future)
	modFieldsToRemove.forEach(function(field) {
		delete formData[field];
	});

	// Submit Consent Capture
	const consentCapture = form.querySelectorAll('.consent-capture');
	const vendors = [];
	if (consentCapture) {
		consentCapture.forEach(function(element, index) {
			formData[`vendorDetails[${index}][key]`] = element.getAttribute('data-key');
			formData[`vendorDetails[${index}][name]`] = element.getAttribute('data-name');
			formData[`vendorDetails[${index}][displayPosition]`] = index + 1;
			formData[`vendorDetails[${index}][consent]`] = element.checked ? 'Yes' : 'No';

			// Setting up vendors array needed for Lead Capture API
			if (element.checked) {
				vendors.push({
					vendorKey: element.getAttribute('data-key'),
					vendorDisplayName: element.getAttribute('data-name')
				});
			}
		});

		window.modForm.opts.consentCaptureVendors = vendors;
	}

	// Pass on any other fields that are still in formData
	formattedData = Object.assign(formattedData, formData);

	// Predefined values
	const path = window.location.pathname !== '/' ? window.location.pathname : '';
	formattedData.site = window.location.host.replace(/(qa|staging|www)./g, '') + path;
	formattedData.siteName = formattedData.site;
	formattedData.HomePhoneType = getPhoneNumberType(formattedData.HP.replace(/\D/g, ''));
	formattedData.HomePhoneConsent = 'Yes';
	formattedData.responseFormat = 'json';

	if (window.modForm.opts.ipAddress) {
		formattedData.IPAddress = window.modForm.opts.ipAddress;
	}

	return formattedData;
}
