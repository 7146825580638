import { getCurrentStep, hashStepTracking, showStep, getHeapSessionIdCookieData, initDuplicateSubmissionExperience } from '../../../helpers';
/**
 * Show next step and potential hash step tracking
 * @returns {Boolean} - Returns false if duplicate submission experience is enabled and user is trying to submit the form again
 */
export function showNextStep() {
	const siblingElement = getCurrentStep().nextElementSibling;

	if (siblingElement && (siblingElement.classList.contains('step') || (siblingElement.hasAttribute('data-bind') && siblingElement.getAttribute('data-bind') === 'step'))) {
		const nextStepId = siblingElement.getAttribute('id');

		if (!window.modForm.opts.ignoreDuplicateSubmissionBlocker && window.modForm.includeDuplicateSubmissionExperience) {
			const heapSessionIdCookieData = getHeapSessionIdCookieData();
			if (heapSessionIdCookieData.heapSessionSubmitCookie !== null &&  window.modForm.getTime < heapSessionIdCookieData.expireInOneHour) {
				initDuplicateSubmissionExperience(heapSessionIdCookieData);
				return false;
			}
		}

		if (window.modForm.opts.hashStepTracking) {
			hashStepTracking(nextStepId);
		}

		if (window.modForm.opts.useAutofill) {
			window.modForm.opts.steps.forEach(function(step) {
				step.classList.remove('autofill');
			});
		}

		showStep(nextStepId);
	}
}
