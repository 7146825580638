/**
 * Mapped service codes and their required attributes
 * @type {Array}
 */
export const serviceCodesAndAttributions = [
	{
		serviceCodes: ['BATHROOM_REFACING'],
		requiredAttributes: ['BathProjectScope', 'OwnHome']
	},
	{
		serviceCodes: ['BATH_REMODEL'],
		requiredAttributes: ['BathProjectScope', 'OptIn1', 'OwnHome']
	},
	{
		serviceCodes: ['GUTTER_COVERS'],
		requiredAttributes: ['OwnHome', 'CommercialLocation']
	}
];
