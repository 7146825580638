import { appendFormInput, saveUserInfoToCookie, populateFormWithZipData } from '../../../helpers';
import { isZipCodeValid } from '../../../validators';
/**
 * Pre-populate any fields with matching query string keys with their values.
 * Info on our Pre-Population Rules:
 * https://confluence.modernize.com/display/ENG/Microsite+Forms%3A+Pre-Population+Rules
 */
export function prepopFormFromURL() {
	Object.keys(window.modForm.opts.getVars).forEach(function(key) {
		if (!window.modForm.opts.ignoreQueryParams.includes(key)) {
			appendFormInput(key, window.modForm.opts.getVars[key]);
		}
		if ('zip' === key) {
			appendFormInput('zip1', window.modForm.opts.getVars[key]);
			appendFormInput('inertZip', window.modForm.opts.getVars[key]);
		}

		if (window.modForm.opts.prepopulateUserInfo) {
			saveUserInfoToCookie(key, window.modForm.opts.getVars[key]);
		}
	});

	// Process zip
	if ('undefined' !== typeof window.modForm.opts.getVars.zip) {
		// TODO: Remove
		if (!window.modForm.opts.prepopulateUserInfo) {
			isZipCodeValid(window.modForm.opts.getVars.zip, function(response) {
				// If success..
				if (response.success) {
					// Populate city & state
					if (response.data) {
						populateFormWithZipData(response.data);

						// Fire callback
						if ('function' === typeof window.modForm.opts.zipCodeValidated) {
							window.modForm.opts.zipCodeValidated(response.data);
						}
					}
				}
			});
		}
	}
}
